import React from "react";
import {ThemeProvider} from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/Navigation";
import Service from "sections/Service";
import CoinFund from "sections/CoinFund";
import UserMap from "sections/UserMap";
import Faq from "sections/Faq";
import Footer from "sections/Footer";
import Fade from 'react-reveal/Fade'

import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";
import Solution from "../sections/Solution";
import PotentiamGivesBack from "../sections/PotentiamGivesBack";
import ProblemMusicIndustory from "../sections/ProblemMusicIndustory";
import OurVision from "../sections/OurVision";
import Banner from "../sections/Banner";

const IndexPage = () => (
    <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <Layout>
            <SEO title="Home"/>
            <Navigation linkMenu={true}/>
            <Fade top>
                <Banner/>
            </Fade>
            <Fade top>
                <OurVision/>
            </Fade>
            <ProblemMusicIndustory/>
            <Solution/>
            <PotentiamGivesBack/>
            <Service/>
            <Fade top>
                <CoinFund/>
            </Fade>
            <Fade top>
                <UserMap/>
            </Fade>
            <Fade top>
                <Faq/>
            </Fade>
            <Fade top>
                <Footer/>
            </Fade>
        </Layout>
    </ThemeProvider>
)

export default IndexPage
